<template>
  <div>
    <v-container>
      <h1 class="mb-2">Menu</h1>
      <v-row>
        <v-col>
          <v-card>
            <v-toolbar dense elevation="0">
              <v-toolbar-title>List Data</v-toolbar-title>

              <v-spacer></v-spacer>

              <v-text-field v-model="search" prepend-icon="mdi-magnify" style="max-width: 300px" hide-details single-line></v-text-field>

              <v-btn icon @click="refreshData()" title="Refresh">
                <v-icon>mdi-sync</v-icon>
              </v-btn>
            </v-toolbar>
            <v-data-table :loading="datatable.loading" :loading-text="datatable.loadingText" :headers="datatable.columns" :items="datatable.data" :search="search" dense class="mb-3 my-0">
              <template v-slot:[`item.action`]="{ item }">
                <a href="javascript:;" @click="getDetail(item.id)">
                  <v-icon small>mdi-pencil</v-icon>
                </a>
              </template>
              <template v-slot:[`item.icon`]="{ item }">
                <v-icon small>{{ item.icon }}</v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      search: "",
      datatable: {
        loading: null,
        loadingText: "Loading... Please wait...",
        columns: [
          { text: "Action", value: "action", align: "center"},
          { text: "Icon", value: "icon" },
          { text: "Id", value: "id" },
          { text: "Title", value: "title" },
          { text: "Uri", value: "uri" },
          { text: "Parent_id", value: "parent_id" },
          { text: "Lastname", value: "last_name" },
          { text: "Sort Order", value: "sort_order" },
        ],
        data: [],
      },
      selectedUsername: null,
    };
  },

  methods: {
    getData() {
      this.datatable.loading = true;
      var params = [];
      this.$axios
        .get("menu/data", {
          params: params,
          cacheConfig: true,
        })
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.data = resData.data.menus;
          }else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.datatable.loading = false;
        });
    },

    refreshData() {
      this.AxiosStorageRemove("GET", "menu/data");
      this.datatable.data = [];
      this.getData();
    },
  },

  mounted() {
    this.requiredLogin();
    this.getData();
  },
};
</script>